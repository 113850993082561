import { useAuthentication } from "@tecnobit-srl/authentication";
import { createState } from "@tecnobit-srl/state";
import { merge, get } from "lodash-es";
import { useMemo } from "react";
const { ContextProvider: CustomActionsProvider, useStore } = createState(
  "custom-actions",
  (set) => ({
    customActions: {},
    addCustomAction: (id, customAction) => {
      set((state) => {
        return {
          ...state,
          customActions: {
            ...state.customActions,
            [id]: customAction
          }
        };
      });
    }
  }),
  (state, props) => ({
    ...state,
    ...props,
    customActions: props.customActions ? merge(state.customActions, props.customActions) : state.customActions
  })
);
const useAddCustomAction = () => {
  return useStore((state) => state.addCustomAction);
};
const useCustomActions = () => {
  return useStore((state) => state.customActions);
};
const useCustomAction = (id) => {
  const customActions = useCustomActions();
  return useMemo(
    () => id ? get(customActions, id) : void 0,
    [customActions, id]
  );
};
const useCoreCustomActions = () => {
  const { login, logout, signup } = useAuthentication();
  return useMemo(
    () => ({
      login: async () => {
        await login();
      },
      logout: async () => {
        await logout();
      },
      signup: async () => {
        await signup();
      },
      back: () => {
        window.history.back();
      },
      go_back: () => {
        window.history.back();
      }
    }),
    [login, logout, signup]
  );
};
export {
  CustomActionsProvider,
  useAddCustomAction,
  useCoreCustomActions,
  useCustomAction,
  useCustomActions
};
